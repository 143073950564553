import React, { useEffect, useState } from "react"
import Select from "react-select"

import { addUpdateUser, getAllFunctions, getAllUsers, getAzureADUsers, getCurrentUserData, getEditUserDetails } from "../../services/ApiServices";
import Loader from "../../components/Loader/Loader";
import toast from "react-hot-toast";
import { changeCurrentUser } from "../../Store/Slices/MSALSlice";
import { useDispatch, useSelector } from "react-redux";
import "./User.scss"

import userInfo from "../../assets/img/icon-user-detail.svg"
import editIcon from "../../assets/img/icon-edit.svg"
// import userImage from "../../assets/img/img-placeholder.png"
import { UserImage } from "../../components/UserImage/UserImage";

const User = () => {
    const currentUser = useSelector((state) => state.MSALAuth.currentUser)
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [allUsers, setAllUsers] = useState([]);
    const [allFunctions, setAllFunctions] = useState([])

    const allCompanies = [
        { Name: "CI ETF Coop", Id: "CI ETF Coop" },
        { Name: "CI III NL Coop", Id: "CI III NL Coop" },
        { Name: "CI IV Coop", Id: "CI IV Coop" },
        { Name: "CI NMF I Coop", Id: "CI NMF I Coop" },
        { Name: "CI V Coop", Id: "CI V Coop" },
        { Name: "CICS MX", Id: "CICS MX" },
        { Name: "CIOK", Id: "CIOK" },
        { Name: "CIOT", Id: "CIOT" },
        { Name: "CISC AUS", Id: "CISC AUS" },
        { Name: "CISC BR", Id: "CISC BR" },
        { Name: "CISC BV", Id: "CISC BV" },
        { Name: "CISC CL", Id: "CISC CL" },
        { Name: "CISC DE", Id: "CISC DE" },
        { Name: "CISC DK", Id: "CISC DK" },
        { Name: "CISC ES", Id: "CISC ES" },
        { Name: "CISC IN", Id: "CISC IN" },
        { Name: "CISC LUX", Id: "CISC LUX" },
        { Name: "CISC MX", Id: "CISC MX" },
        { Name: "CISC PH", Id: "CISC PH" },
        { Name: "CISC SG", Id: "CISC SG" },
        { Name: "CISC ST AU", Id: "CISC ST AU" },
        { Name: "CISC UK", Id: "CISC UK" },
        { Name: "CISC US", Id: "CISC US" },
        { Name: "CISC VN", Id: "CISC VN" },
    ]
    const allLocations = [
        { Name: "AU - Melbourne", Id: "AU - Melbourne" },
        { Name: "AU - Perth", Id: "AU - Perth" },
        { Name: "BR - Sao Paulo", Id: "BR - Sao Paulo" },
        { Name: "CL - Santiago", Id: "CL - Santiago" },
        { Name: "DE - Hamburg", Id: "DE - Hamburg" },
        { Name: "DK - Copenhagen", Id: "DK - Copenhagen" },
        { Name: "DK - Esbjerg", Id: "DK - Esbjerg" },
        { Name: "DK - Tønder", Id: "DK - Tønder" },
        { Name: "ES - Madrid", Id: "ES - Madrid" },
        { Name: "FI - Helsinki", Id: "FI - Helsinki" },
        { Name: "IND - Mumbai", Id: "IND - Mumbai" },
        { Name: "IND - New Delhi", Id: "IND - New Delhi" },
        { Name: "KOR - Seoul", Id: "KOR - Seoul" },
        { Name: "LUX - Luxembourg", Id: "LUX - Luxembourg" },
        { Name: "MX - Mexico", Id: "MX - Mexico" },
        { Name: "NL - Utrecht", Id: "NL - Utrecht" },
        { Name: "OM - Oman", Id: "OM - Oman" },
        { Name: "PH - Philippines", Id: "PH - Philippines" },
        { Name: "SG - Singapore", Id: "SG - Singapore" },
        { Name: "TW - Taipei", Id: "TW - Taipei" },
        { Name: "UK - London", Id: "UK - London" },
        { Name: "UK - Sheffield", Id: "UK - Sheffield" },
        { Name: "US - Chicago", Id: "US - Chicago" },
        { Name: "US - Conneticut", Id: "US - Conneticut" },
        { Name: "US - DC", Id: "US - DC" },
        { Name: "US - Houston", Id: "US - Houston" },
        { Name: "US - New York", Id: "US - New York" },
        { Name: "US - Tennessee", Id: "US - Tennessee" },
        { Name: "US - Virginia", Id: "US - Virginia" },
        { Name: "VN - Hanoi", Id: "VN - Hanoi" },
    ]
    const allRegions = [
        { Name: "CISC Americas", Id: "CISC Americas" },
        { Name: "CISC APAC", Id: "CISC APAC" },
        { Name: "CISC APAC ST", Id: "CISC APAC ST" },
        { Name: "CISC Asia", Id: "CISC Asia" },
        { Name: "CISC EMEA", Id: "CISC EMEA" },
        { Name: "CISC Global", Id: "CISC Global" },
        { Name: "CISC LATAM", Id: "CISC LATAM" },
    ]
    const allPositionLevels = [
        { Name: 'Analyst', Id: 'Analyst' },
        { Name: 'Associate', Id: 'Associate' },
        { Name: 'Senior Associate', Id: 'Senior Associate' },
        { Name: 'Manager', Id: 'Manager' },
        { Name: 'Senior Manager', Id: 'Senior Manager' },
        { Name: 'Vice President', Id: 'Vice President' },
        { Name: 'Director', Id: 'Director' },
        { Name: 'Senior Director', Id: 'Senior Director' },
        { Name: 'Managing Director', Id: 'Managing Director' },
        { Name: 'N/A', Id: 'N/A' },
    ]

    const [editUserDetails, setEditUserDetails] = useState({})
    // const [profileImageUrl, setProfileImageUrl] = useState(userImage);
    const [inputSearchUser, setInputSearchUser] = useState("")

    const [queryStringL2, setQueryStringL2] = useState("")
    const [queryStringL3, setQueryStringL3] = useState("")
    const [queryStringPA, setQueryStringPA] = useState("")
    const [queryStringDM, setQueryStringDM] = useState("")

    const [allL2Users, setAllL2Users] = useState([])
    const [allL3Users, setAllL3Users] = useState([])
    const [allPAUsers, setAllPAUsers] = useState([])
    const [allDMUsers, setAllDMUsers] = useState([])

    const [userL2, setUserL2] = useState("")
    const [userL3, setUserL3] = useState("")
    const [userPA, setUserPA] = useState("")
    const [userDM, setUserDM] = useState([])


    const handleStatusAndRole = async (type, user) => {
        let payload = {}
        if (type === "status") {
            payload = {
                Id: user?.Id,
                IsAdmin: user?.IsAdmin,
                IsActive: !user?.IsActive,
                PAUPN: user?.PADetails[0]?.PAUPN,
                ManagerL2: user?.ManagerL2,
                ManagerL2Name: user?.ManagerL2Name,
                ManagerL3: user?.ManagerL3,
                ManagerL3Name: user?.ManagerL3Name,
                ServiceFunction: user?.ServiceFunction,
                PositionLevel: user?.PositionLevel,
                StartDate: user?.StartDate,
                EndDate: user?.EndDate,
                JobTitle: user?.JobTitle,
                Managers: user?.Managers
            }
        } else {
            payload = {
                Id: user?.Id,
                IsAdmin: !user?.IsAdmin,
                IsActive: user?.IsActive,
                PAUPN: user?.PADetails[0]?.PAUPN,
                ManagerL2: user?.ManagerL2,
                ManagerL2Name: user?.ManagerL2Name,
                ManagerL3: user?.ManagerL3,
                ManagerL3Name: user?.ManagerL3Name,
                ServiceFunction: user?.ServiceFunction,
                PositionLevel: user?.PositionLevel,
                StartDate: user?.StartDate,
                EndDate: user?.EndDate,
                JobTitle: user?.JobTitle,
                Managers: user?.Managers
            }
        }

        await addUpdateUser(payload).then(async (response) => {
            if (response?.Success) {
                toast.success(response.Message)
                if (editUserDetails?.Email === currentUser?.Data?.Email) {
                    await getCurrentUserData().then(
                        async (response) => {
                            if (response?.Data?.IsAdmin) {
                                await GetAllUsers();
                            }
                        })
                } else {
                    await GetAllUsers()
                }
            } else {
                toast.error(response.Message)
            }
        }).catch(async (error) => {
            if (allUsers?.Email === currentUser?.Data?.Data?.Email) {
                await GetCurrentUser()
            } else {
                await GetAllUsers()
            }
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const UpdateUserDetails = async () => {
        setIsLoading(true);

        const payload = {
            Id: editUserDetails?.Id,
            IsAdmin: editUserDetails?.IsAdmin,
            IsActive: editUserDetails?.IsActive,
            PAUPN: editUserDetails?.PADetails[0]?.PAUPN,
            ManagerL2: editUserDetails?.ManagerL2,
            ManagerL2Name: allL2Users?.find((u) => u?.UPN === userL2)?.Name,
            ManagerL3: editUserDetails?.ManagerL3,
            ManagerL3Name: allL3Users?.find((u) => u?.UPN === userL3)?.Name,
            ServiceFunction: editUserDetails?.ServiceFunction,
            Company: editUserDetails?.Company,
            Region: editUserDetails?.Region,
            Location: editUserDetails?.Location,
            PositionLevel: editUserDetails?.PositionLevel,
            StartDate: editUserDetails?.StartDate,
            EndDate: editUserDetails?.EndDate,
            JobTitle: editUserDetails?.JobTitle,
            Managers: editUserDetails?.Managers.filter((e) => {
                return e !== undefined
            }),
            ProjectApprovers: editUserDetails?.ProjectApprovers?.map((dmUser) => ({
                ApproverName: dmUser?.ApproverName,
                ApproverUPN: dmUser?.ApproverUPN
            }))
        }

        await addUpdateUser(payload).then(async (response) => {
            if (response?.Success) {
                toast.success(response.Message)
                if (editUserDetails?.Email === currentUser?.Data?.Email) {
                    await getCurrentUserData().then(
                        async (response) => {
                            if (response?.Data?.IsAdmin) {
                                await GetAllUsers();
                            }
                        })
                } else {
                    await GetAllUsers()
                }
            } else {
                toast.error(response.Message)
            }
        }).catch(async (error) => {
            if (allUsers?.Email === currentUser?.Data?.Email) {
                await GetCurrentUser()
            } else {
                await GetAllUsers()
            }
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const GetCurrentUser = async () => {
        getCurrentUserData().then((response) => {
            dispatch(changeCurrentUser(response?.Data))
        }).catch((error) => {
            dispatch(changeCurrentUser({}))
            console.warn("error", error);
        })
        return
    }

    const GetEditUserDetails = async (upn) => {
        setIsLoading(true)
        await getEditUserDetails(upn).then((response) => {
            if (response?.Data) {
                setEditUserDetails(response?.Data[0])

                setAllL2Users([{ Name: response?.Data[0] && response?.Data[0]?.ManagerL2Name !== ("" && null) ? response?.Data[0]?.ManagerL2Name : "", UPN: response?.Data[0] && response?.Data[0]?.ManagerL2 }])
                setUserL2(response?.Data[0]?.ManagerL2)

                setAllL3Users([{ Name: response?.Data[0] && response?.Data[0]?.ManagerL3Name !== ("" && null) ? response?.Data[0]?.ManagerL3Name : "", UPN: response?.Data[0] && response?.Data[0]?.ManagerL3 }])
                setUserL3(response?.Data[0]?.ManagerL3)

                const PADetails = response?.Data[0] && response?.Data[0]?.PADetails?.length > 0 ? response?.Data[0]?.PADetails : []
                setAllPAUsers(PADetails?.length > 0 ? [{ Name: PADetails[0]?.PAName, UPN: PADetails[0]?.PAUPN }] : [])
                setUserPA(response?.Data[0]?.PADetails[0]?.PAUPN)

                const DMDetails = response?.Data[0] && response?.Data[0]?.Managers?.length > 0 ? response?.Data[0]?.Managers : []
                setAllDMUsers(DMDetails?.length > 0 ? DMDetails?.map((dm) => ({ Name: dm?.Name, UPN: dm?.UPN })) : [])
                setUserDM(response?.Data[0]?.Managers)

            }
        }).catch((error) => {
            console.warn("error", error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // Get All Users
    const GetAllUsers = async () => {
        setIsLoading(true);
        await getAllUsers().then((response) => {
            if (response?.Success) {
                setAllUsers(response?.Data)
            } else {
                setAllUsers([])
            }
        }).catch((error) => {
            console.warn("error", error);
        }).finally(() => {
            setIsLoading(false)
        })

        return
    }

    // Get All Function
    const GetAllFunctions = async () => {
        setIsLoading(true);

        await getAllFunctions().then((response) => {
            if (response?.Success) {
                setAllFunctions(response?.Data)
            } else {
                setAllFunctions([])
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (queryStringL2.length >= 3) {
                getAzureADUsers(queryStringL2).then((response) => {
                    if (response?.Data) {
                        setAllL2Users(response?.Data)
                    }
                }).catch((error) => {
                    console.warn("error", error)
                }).finally(() => {
                });
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [queryStringL2])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (queryStringL3.length >= 3) {
                getAzureADUsers(queryStringL3).then((response) => {
                    if (response?.Data) {
                        setAllL3Users(response?.Data)
                    }
                }).catch((error) => {
                    console.warn("error", error)
                }).finally(() => {
                });
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [queryStringL3])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (queryStringPA.length >= 3) {
                getAzureADUsers(queryStringPA).then((response) => {
                    if (response?.Data) {
                        setAllPAUsers(response?.Data)
                    }
                }).catch((error) => {
                    console.warn("error", error)
                }).finally(() => {
                });
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [queryStringPA])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (queryStringDM.length >= 3) {
                getAzureADUsers(queryStringDM).then((response) => {
                    if (response?.Data) {
                        const existingUsersSet = new Set(allDMUsers.map(user => user.UPN));

                        const newUsers = response?.Data.filter(dm => !existingUsersSet.has(dm.UPN))
                            .map(dm => ({ Name: dm.Name, UPN: dm.UPN }));

                        setAllDMUsers(prevUsers => [...prevUsers, ...newUsers]);
                    }
                }).catch((error) => {
                    console.warn("error", error)
                }).finally(() => {
                });
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [queryStringDM])

    useEffect(() => {
        GetAllUsers()
        GetAllFunctions()
    }, [])



    return (
        <>
            {isLoading && <Loader />}

            <main id="main" className="user-main">
                <div className="pagetitle mb d-flex justify-content-between">
                    <h1>Users</h1>
                    <div className="col-lg-3 text-right d-flex align-items-end gap-2">
                        <input
                            type="text"
                            className="form-control mb-3"
                            value={inputSearchUser}
                            onChange={(e) => setInputSearchUser(e.target.value)}
                            placeholder="Search user"
                        />
                    </div>
                </div>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <table className="table tableCustom table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Display Name</th>
                                        <th scope="col" align="center">Email</th>
                                        <th scope="col" align="center">Status</th>
                                        <th scope="col" align="center">Role</th>
                                        <th scope="col" align="center">Location</th>
                                        <th scope="col" className="text-end"></th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {allUsers?.length > 0 && (
                                        allUsers?.filter((u) =>
                                            (u?.Email?.length > 0 && u?.Email?.toLowerCase().includes(inputSearchUser.trim().toLowerCase())) ||
                                            (u?.Name?.length > 0 && u?.Name?.toLowerCase().includes(inputSearchUser.trim().toLowerCase()))
                                        )?.map((user, UserId) =>
                                            <tr key={UserId} className="table-row">
                                                <td className="txt-medium">
                                                    <div className="nav-profile d-flex align-items-center pe-0">
                                                        <span>{user?.Name !== ("" && null) ? user?.Name : "--"}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="txt-medium">
                                                        {user?.Email !== ("" && null) ? user?.Email : "--"}
                                                    </div>
                                                </td>
                                                <td className="col-status">
                                                    <button
                                                        className={`btn active-btn ${user?.IsActive ? "btn-outline-success" : "btn-outline-danger"}`}
                                                        onClick={() => handleStatusAndRole("status", user)}
                                                    >
                                                        {user?.IsActive ? "Active" : "Inactive"}
                                                    </button>
                                                </td>
                                                <td className="col-role">
                                                    <button className={`btn admin-btn ${user?.IsAdmin ? "btn-outline-danger" : "btn-outline-success"}`}
                                                        onClick={() => handleStatusAndRole("role", user)}
                                                    >
                                                        {user?.IsAdmin ? "Admin" : "Regular"}
                                                    </button>
                                                </td>
                                                <td>
                                                    <div className="nav-profile d-flex align-items-center pe-0">
                                                        <span>{user?.Location ? user?.Location : "--"}</span>
                                                    </div>
                                                </td>
                                                <td align="right">
                                                    <button
                                                        className="btn btn-primary-w btn-icon me-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#userinfo"
                                                        onClick={() => GetEditUserDetails(user?.UPN)}
                                                    >
                                                        <img src={userInfo} className="user-btn" alt="" />
                                                    </button>
                                                    <button
                                                        className="btn btn-primary-w btn-icon me-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editinfo"
                                                        onClick={() => GetEditUserDetails(user?.UPN)}
                                                    >
                                                        <img src={editIcon} className="edit-btn" alt="" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                {/* modal user info */}
                <div className="modal userinfo fade" id="userinfo" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">User info.</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    <div className="user-details col-12 mb-3">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="nav-profile d-flex align-items-center pe-0" >
                                                    <UserImage className="rounded-circle profile-width me-3" email={editUserDetails?.Email} size={150} border="none" />
                                                </div>
                                            </div>
                                            <div className="details col-7 mt-2">
                                                <h3>{editUserDetails?.Name !== ("" && null) ? editUserDetails?.Name : "--"}</h3>
                                                <div className="designation mb-4 txt-gray">{editUserDetails?.JobTitle !== ("" && null) ? editUserDetails?.JobTitle : "--"}</div>
                                                <div className="email mb-1"><a href="#" className="txt-black">{editUserDetails?.Email}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="view">
                                        {/* Status */}
                                        <div className="status-toggle col-12 mb-3 mt-5">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label">Status</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    <div className="form-check form-switch d-inline-flex">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDisabled"
                                                            checked={editUserDetails?.IsActive}
                                                            disabled
                                                        />
                                                        <span className="textgreen">Active</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Role */}
                                        <div className="role col-12 mb-2 role-row">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Role</label></div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.IsAdmin ?
                                                        <div className="btn btn-outline-danger">Admin</div>
                                                        : <div className="btn btn-outline-success">Regular</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* Department */}
                                        <div className="department col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Department</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.Department !== null && editUserDetails?.Department?.length > 0 ? editUserDetails?.Department : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Job title */}
                                        <div className="job-title col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Job title</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.JobTitle !== null && editUserDetails?.JobTitle?.length > 0 ? editUserDetails?.JobTitle : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Company */}
                                        <div className="company col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Company</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.Company !== null && editUserDetails?.Company?.length > 0 ? editUserDetails?.Company : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Location */}
                                        <div className="business-address col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Location</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.Location !== null && editUserDetails?.Location?.length > 0 ? editUserDetails?.Location : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Country */}
                                        <div className="country col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Country</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.Country !== null && editUserDetails?.Country?.length > 0 ? editUserDetails?.Country : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Region */}
                                        <div className="region col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Region</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.Region !== null && editUserDetails?.Region?.length > 0 ? editUserDetails?.Region : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Position Level */}
                                        <div className="employment col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Position Level</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.PositionLevel !== null && editUserDetails?.PositionLevel?.length > 0 ? editUserDetails?.PositionLevel : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Service Function */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Service Function</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.ServiceFunction !== null && editUserDetails?.ServiceFunction > 0
                                                        ? allFunctions.length > 0 && allFunctions?.find((func) => func?.Id === editUserDetails?.ServiceFunction)?.Name
                                                        : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* L1 */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Manager L1</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.ManagerL1Name !== null && editUserDetails?.ManagerL1Name?.length > 0 ? editUserDetails?.ManagerL1Name : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* L2 */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Manager L2</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.ManagerL2Name !== null && editUserDetails?.ManagerL2Name?.length > 0 ? editUserDetails?.ManagerL2Name : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* L3 */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Manager L3</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.ManagerL3Name !== null && editUserDetails?.ManagerL3Name?.length > 0 ? editUserDetails?.ManagerL3Name : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* PA */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2 text-nowrap">Personal Assistant</label>
                                                </div>
                                                <div className="col-8 mt-2">
                                                    {editUserDetails?.PADetails?.length >= 0 && editUserDetails?.PADetails[0]?.PAName !== null && editUserDetails?.PADetails[0]?.PAName?.length > 0 ? editUserDetails?.PADetails[0]?.PAName : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* DM */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2 text-nowrap">Delegate Manager</label>
                                                </div>
                                                <div className="col-6 mt-2">
                                                    {editUserDetails?.Managers && editUserDetails?.Managers?.length > 0
                                                        ? editUserDetails?.Managers?.map((users, index) =>
                                                            <span>{users?.Name}{editUserDetails?.Managers?.length > 1 ? (editUserDetails?.Managers?.length - 1 !== index && ", ") : ""}</span>
                                                        )
                                                        : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Cost Center */}
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2 text-nowrap">Cost Center</label>
                                                </div>
                                                <div className="col-6 mt-2">
                                                    {editUserDetails?.CostCenter ? editUserDetails?.CostCenter : "--"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <hr />
                            <div className="modal-footer">
                                <button type="button" className="btn edit-btn btn-primary btn-lg btn-full" data-bs-toggle="modal" data-bs-target="#editinfo">Edit</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal edit info */}
                <div className="modal editinfo fade" id="editinfo" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit info.</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body mb-4">
                                <form className="row g-3">
                                    <div className="user-details col-12 mb-3">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="nav-profile d-flex align-items-center pe-0">
                                                    <UserImage className="rounded-circle profile-width me-3" email={editUserDetails?.Email} size={150} border="none" />
                                                </div>
                                            </div>
                                            <div className="details col-7 mt-2">
                                                <h3>{editUserDetails?.Name !== ("" && null) ? editUserDetails?.Name : "--"}</h3>
                                                <div className="designation mb-4 txt-gray">{editUserDetails?.JobTitle !== ("" && null) ? editUserDetails?.JobTitle : "--"}</div>
                                                <div className="email mb-1"><a href="#" className="txt-black">{editUserDetails?.Email !== ("" && null) ? editUserDetails?.Email : "--"}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="edit">
                                        {/* Status */}
                                        <div className="status-toggle col-12 mb-3 mt-5">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Status</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    <div className="form-check form-switch d-inline-flex">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDisabled"
                                                            checked={editUserDetails?.IsActive}
                                                            onClick={() => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                IsActive: !prev?.IsActive
                                                            }))}
                                                        />
                                                        <span className="textgreen">Active</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Role */}
                                        <div className="role col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Role</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={!editUserDetails?.IsAdmin} onChange={() => setEditUserDetails((prev) => ({ ...prev, IsAdmin: false }))} type="radio" name="role" id="roleRegular" defaultValue="regular" />
                                                                <label className="form-check-label textgreen" htmlFor="roleRegular">Regular</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-check">
                                                                <input className="form-check-input " checked={editUserDetails?.IsAdmin} onChange={() => setEditUserDetails((prev) => ({ ...prev, IsAdmin: true }))} type="radio" name="role" id="roleAdmin" defaultValue="admin" />
                                                                <label className="form-check-label textred" htmlFor="roleAdmin">Admin
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Department */}
                                        <div className="department col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Department</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {/* <input
                                                        type="text"
                                                        className={`form-control mb-1`}
                                                        style={{ padding: "9px 20px", paddingLeft: "10px" }}
                                                        value={department}
                                                        onChange={(e) => setDepartment(e.target.value)}
                                                        placeholder="Please Enter Business address..."
                                                    /> */}
                                                    {editUserDetails?.Department !== null && editUserDetails?.Department?.length > 0 ? editUserDetails?.Department : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Job Title */}
                                        <div className="job-title col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Job title</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {editUserDetails?.JobTitle !== null && editUserDetails?.JobTitle?.length > 0 ? editUserDetails?.JobTitle : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Company */}
                                        <div className="company col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Company</label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.Company
                                                                ? ({
                                                                    label: allCompanies?.find((company) => company?.Id === editUserDetails?.Company)?.Name,
                                                                    value: allCompanies?.find((company) => company?.Id === editUserDetails?.Company)?.Id
                                                                })
                                                                : { value: "", label: "Select Company" }}
                                                            onChange={(e) => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                Company: e.value
                                                            }))}
                                                            options={
                                                                [{ value: "", label: "Select Company" },
                                                                ...allCompanies?.map((company) =>
                                                                    ({ label: company?.Name, value: company?.Id }))
                                                                ]}
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Location */}
                                        <div className="business-address col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Location</label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.Location
                                                                ? ({
                                                                    label: allLocations?.find((location) => location?.Id === editUserDetails?.Location)?.Name,
                                                                    value: allLocations?.find((location) => location?.Id === editUserDetails?.Location)?.Id
                                                                })
                                                                : { value: "", label: "Select Location" }}
                                                            onChange={(e) => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                Location: e.value
                                                            }))}
                                                            options={
                                                                [{ value: "", label: "Select Location" },
                                                                ...allLocations?.map((location) =>
                                                                    ({ label: location?.Name, value: location?.Id }))
                                                                ]}
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Country */}
                                        <div className="country col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Country</label>
                                                </div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {/* <input
                                                        type="text"
                                                        className={`form-control mb-1`}
                                                        style={{ padding: "9px 20px", paddingLeft: "10px" }}
                                                        value={country}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        placeholder="Please Enter Business address..."
                                                    /> */}
                                                    {editUserDetails?.Country !== null && editUserDetails?.Country?.length > 0 ? editUserDetails?.Country : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Region */}
                                        <div className="region col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Region</label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.Region
                                                                ? ({
                                                                    label: allRegions?.find((region) => region?.Id === editUserDetails?.Region)?.Name,
                                                                    value: allRegions?.find((region) => region?.Id === editUserDetails?.Region)?.Id
                                                                })
                                                                : { value: "", label: "Select Region" }}
                                                            onChange={(e) => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                Region: e.value
                                                            }))}
                                                            options={
                                                                [{ value: "", label: "Select Region" },
                                                                ...allRegions?.map((region) =>
                                                                    ({ label: region?.Name, value: region?.Id }))
                                                                ]}
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Position Level */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Position Level</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.PositionLevel
                                                                ? ({
                                                                    label: allPositionLevels?.find((pos) => pos?.Id === editUserDetails?.PositionLevel)?.Name,
                                                                    value: allPositionLevels?.find((pos) => pos?.Id === editUserDetails?.PositionLevel)?.Id
                                                                })
                                                                : { value: "", label: "Select Position Level" }}
                                                            onChange={(e) => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                PositionLevel: e.value
                                                            }))}
                                                            options={
                                                                [{ value: "", label: "Select Position Level" },
                                                                ...allPositionLevels?.map((pos) =>
                                                                    ({ label: pos?.Name, value: pos?.Id }))
                                                                ]}
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Service Function */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Service Function</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={allFunctions?.length > 0 && editUserDetails?.ServiceFunction
                                                                ? ({
                                                                    label: allFunctions?.find((func) => func?.Id === editUserDetails?.ServiceFunction)?.Name,
                                                                    value: allFunctions?.find((func) => func?.Id === editUserDetails?.ServiceFunction)?.Id
                                                                })
                                                                : { value: 0, label: "Select Service Function" }}
                                                            onChange={(e) => setEditUserDetails((prev) => ({
                                                                ...prev,
                                                                ServiceFunction: Number(e.value)
                                                            }))}
                                                            options={allFunctions?.length > 0
                                                                ? [{ value: 0, label: "Select Service Function" },
                                                                ...allFunctions?.filter((d) => d?.IsActive)?.map((func) =>
                                                                    ({ label: `${func?.Name}`, value: func?.Id }))]
                                                                : []
                                                            }
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* L1 */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Manager L1</label></div>
                                                <div className="col-8 d-flex align-items-center">
                                                    {/* <div className="row">
                                                        <Select
                                                            value={allUsers?.length > 0 && managerL1
                                                                ?
                                                                ({
                                                                    label: allUsers?.find((user) => user?.Id === managerL1)?.Name,
                                                                    value: allUsers?.find((user) => user?.Id === managerL1).Id
                                                                })
                                                                : { value: 0, label: "Select Manager L1" }}
                                                            onChange={(e) => setManagerL1(Number(e.value))}
                                                            options={allUsers?.length > 0
                                                                ? [
                                                                    { value: 0, label: "Select Manager L1" }
                                                                    , ...allUsers?.filter((users) => users?.IsActive).map((users) =>
                                                                        ({ label: `${users.Name}`, value: users.Id }))] : []
                                                            }

                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div> */}
                                                    {editUserDetails?.ManagerL1Name !== null && editUserDetails?.ManagerL1Name?.length > 0 ? editUserDetails?.ManagerL1Name : "--"}
                                                </div>
                                            </div>
                                        </div>
                                        {/* L2 */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Manager L2</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.ManagerL2 ? ({
                                                                label: allL2Users?.find((user) => user?.UPN === userL2)?.Name,
                                                                value: allL2Users?.find((user) => user?.UPN === userL2)?.UPN
                                                            }) : null}
                                                            inputValue={queryStringL2}
                                                            onInputChange={(e) => setQueryStringL2(e)}
                                                            onChange={(e) => {
                                                                setUserL2(e.value)
                                                                setEditUserDetails((prev) => ({
                                                                    ...prev,
                                                                    ManagerL2: e.value,
                                                                    ManagerL2Name: e.label
                                                                }))
                                                            }}
                                                            options={allL2Users?.length > 0
                                                                ? [{ value: 0, label: "Select Manager L2" },
                                                                ...allL2Users?.map((u) => ({
                                                                    label: u?.Name,
                                                                    value: u?.UPN
                                                                }))]
                                                                : []
                                                            }
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* L3 */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2">Manager L3</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.ManagerL3 ? ({
                                                                label: allL3Users?.find((user) => user?.UPN === userL3)?.Name,
                                                                value: allL3Users?.find((user) => user?.UPN === userL3)?.UPN
                                                            }) : null}
                                                            inputValue={queryStringL3}
                                                            onInputChange={(e) => setQueryStringL3(e)}
                                                            onChange={(e) => {
                                                                setUserL3(e.value)
                                                                setEditUserDetails((prev) => ({
                                                                    ...prev,
                                                                    ManagerL3: e.value,
                                                                    ManagerL3Name: e.label
                                                                }))
                                                            }}
                                                            options={allL3Users?.length > 0
                                                                ? [{ value: 0, label: "Select Manager L3" },
                                                                ...allL3Users?.map((u) => ({
                                                                    label: u?.Name,
                                                                    value: u?.UPN
                                                                }))]
                                                                : []
                                                            }
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Personal Assistant */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2 text-nowrap">Personal Assistant</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.PADetails && editUserDetails?.PADetails?.length > 0 ? ({
                                                                label: allPAUsers?.find((user) => user?.UPN === userPA)?.Name,
                                                                value: allPAUsers?.find((user) => user?.UPN === userPA)?.UPN
                                                            }) : null}
                                                            inputValue={queryStringPA}
                                                            onInputChange={(e) => setQueryStringPA(e)}
                                                            onChange={(e) => {
                                                                setUserPA(e.value)
                                                                setEditUserDetails((prev) => ({
                                                                    ...prev,
                                                                    PADetails: [{
                                                                        PAName: e.label,
                                                                        PAUPN: e.value,
                                                                    }],
                                                                }))
                                                            }}
                                                            options={allPAUsers?.length > 0
                                                                ? [{ value: 0, label: "Select Personal Assistant" },
                                                                ...allPAUsers?.map((users) => ({
                                                                    label: users?.Name,
                                                                    value: users?.UPN
                                                                }))]
                                                                : []
                                                            }
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Delegate Manager */}
                                        <div className="employment col-12 mb-2">
                                            <div className="row">
                                                <div className="col-4"><label className="form-label mt-2 text-nowrap">Delegate Manager</label></div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={editUserDetails?.Managers && editUserDetails?.Managers?.length > 0 ? (
                                                                editUserDetails?.Managers?.map((dmUser) => ({
                                                                    label: userDM?.find((dm) => dm?.UPN === dmUser?.UPN)?.Name,
                                                                    value: userDM?.find((dm) => dm?.UPN === dmUser?.UPN)?.UPN
                                                                }))
                                                            ) : null}
                                                            isMulti
                                                            inputValue={queryStringDM}
                                                            onInputChange={(e) => setQueryStringDM(e)}
                                                            onChange={(e) => {
                                                                setUserDM(e?.map((d) => allDMUsers?.find((user) => user?.UPN === d?.value) && ({
                                                                    Name: d?.label,
                                                                    UPN: d?.value
                                                                })))
                                                                setEditUserDetails((prev) => ({
                                                                    ...prev,
                                                                    Managers: e?.map((d) => allDMUsers?.find((user) => user?.UPN === d?.value) && ({
                                                                        Name: d?.label,
                                                                        UPN: d?.value
                                                                    }))
                                                                }));
                                                            }}
                                                            options={allDMUsers?.length > 0
                                                                ? [...allDMUsers?.map((users) => ({
                                                                    label: users?.Name,
                                                                    value: users?.UPN,
                                                                }))]
                                                                : []
                                                            }
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Cost Center */}
                                        <div className="company col-12 mb-2 ">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label mt-2">Cost Center</label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <Select
                                                            value={{ value: "", label: "Select Cost Center" }}
                                                            // onChange={(e) => setEditUserDetails((prev) => ({
                                                            //     ...prev,
                                                            //     CostCenter: e.value
                                                            // }))}
                                                            options={
                                                                [{ value: "", label: "Select Cost Center" },
                                                                    // ...allCompanies?.map((company) =>
                                                                    //     ({ label: company?.Name, value: company?.Id }))
                                                                ]}
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                DropdownIndicator: () => null,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <hr />
                            <div className="modal-footer">
                                <button type="button" className="btn cancel-btn btn-transparent btn-lg" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" onClick={() => UpdateUserDetails()} className="btn update-btn btn-primary btn-lg" data-bs-dismiss="modal">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default User;
